<template>
  <ftx-dialog :dialog="dialog" maximized position="bottom">
    <template v-slot:DialogContent>
      <q-card class="cmsDialog">
        <div class="dialogHeader">
          <q-btn class="close" flat dense v-close-popup>
            <SvgIcon icon="close" />
          </q-btn>
          <div class="container">
            <div class="row no-wrap">
              <div class="col text-center">
                <h4
                  class="text-h5 text-black q-mt-none q-mb-md"
                  v-text="properties.title"
                />
              </div>
            </div>
          </div>
        </div>
        <q-scroll-area
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          style="height:calc(90vh - 130px);max-width: 100%;"
        >
          <div class="container">
            <div class="row text-center">
              <div class="col-12" v-html="properties.content" />
            </div>
          </div>
        </q-scroll-area>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
export default {
  name: 'CMSDialog',
  data() {
    return {
      dialog: 'CMSDialog',
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
}
</script>

<style lang="scss">
/* Write Review Dialog */
.cmsDialog {
  // padding: 0px 0 40px;
  padding: 0px;
  .dialogHeader {
    padding: 30px 0 30px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 599px) {
      padding: 20px 0;
      background: $primary;
      background: var(--q-color-primary) !important;
      color: $white;
      margin-bottom: 20px;
      h4.text-h5 {
        padding-right: 60px;
        font-size: 18px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
  .container {
    max-width: 660px;
  }

  @media (max-width: 599px) {
    padding: 0px 0 20px;
  }
}
/* Write Review Dialog */
</style>
